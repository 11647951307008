import React from 'react';
import { IItemRendererProps, Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';

import * as styles from './index.module.scss';

export const IssuedSuggestCom = Suggest.ofType<string>();

export const issuedSuggestItemRender = (item: string, p: IItemRendererProps) => {
    return <MenuItem key={p.index} text={item} onClick={p.handleClick} />;
};

export const issuedSuggestInputStyle = styles.issued_suggest_input;
