export interface FullName {
    first_name: string;
    last_name: string;
    patronymic: string;
}

export const initialFullName: FullName = {
    first_name: '',
    last_name: '',
    patronymic: '',
};

export function splitFullName(fullName: string): FullName {
    try {
        const arr = fullName.trim().split(' ');

        return {
            ...initialFullName,
            last_name: arr.length >= 1 ? arr[0].trim() : '',
            first_name: arr.length >= 2 ? arr[1].trim() : '',
            patronymic: arr.length >= 3 ? arr.splice(2, arr.length).join(' ') : '',
        };
    } catch (e) {
        return { ...initialFullName };
    }
}

export function joinFullName(payload: FullName): string {
    return [payload.last_name, payload.first_name, payload.patronymic].join(' ');
}
