import { Intent } from '@blueprintjs/core';
import { useState } from 'react';
import { buildQuery, getAccessToken, Request } from '@resources/helpers/request';
import { AppToaster } from '@components/ui/toaster';

export const useDocumentDownload = () => {
    const [loadingDocument, setLoadingDocument] = useState<boolean>(false);

    const fetchDocument = (params: { visit_id?: number; docs?: string; uuid?: string }) => {
        setLoadingDocument(true);

        const url = `${process.env.HOST_API}/v1/document${buildQuery(params)}`;
        const headers = { Authorization: `Bearer ${getAccessToken()}` };

        return fetch(url, { headers })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                return res.blob();
            })
            .then((blob) => {
                var blobURL = URL.createObjectURL(blob);

                const iframe = document.createElement('iframe'); //load content in an iframe to print later
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };

                // var windowUrl = window.URL || window.webkitURL;
                // var url = windowUrl.createObjectURL(blob);
                // var anchor = document.createElement('a');
                // anchor.href = url;
                // anchor.download = `${visit._card.unique_id}_${moment
                //     .utc(visit.created_at)
                //     .local()
                //     .format(FORMAT_DATETIME_FILE)}.pdf`;
                // anchor.click();
            })
            .catch((e) => {
                AppToaster.show({
                    message: e.message,
                    icon: 'error',
                });
            })
            .finally(() => {
                setLoadingDocument(false);
            });
    };

    const downloadDocumentByLastVisit = (visitId: number) => {
        return fetchDocument({ visit_id: visitId });
    };

    const downloadDocumentByDocs = (visitId: number, docs: string[]) => {
        return fetchDocument({ visit_id: visitId, docs: docs.join(',') });
    };

    const downloadDocument = (visitId: number, uuid: string) => {
        return fetchDocument({ visit_id: visitId, uuid });
    };

    const deleteDocument = (uuid: string) => {
        setLoadingDocument(true);

        return Request.remove(`/v1/document/${uuid}`)
            .then(() => {
                AppToaster.show({
                    message: 'Документ успешно удален',
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                });
            })
            .finally(() => {
                setLoadingDocument(false);
            });
    };

    return {
        downloadDocumentByLastVisit,
        downloadDocumentByDocs,
        downloadDocument,
        deleteDocument,
        loadingDocument,
    };
};
