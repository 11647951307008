import { useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { Request } from '@resources/helpers/request';
import { AppToaster } from '@components/ui/toaster';

export enum SIGNATURE_STATUS {
    WAIT = 1,
    RECEIVE = 2,
    COMPLETE = 3,
}

type UseSignatureProps = {
    uuid: string;
    onReceived: (uuid: string) => void;
};

export const useSignature = (props: UseSignatureProps) => {
    const [loading, setLoading] = useState<{ wait: boolean; receive: boolean; complete: boolean }>({
        wait: false,
        receive: false,
        complete: false,
    });
    const [currentUuid, setCurrentUuid] = useState<string>(props.uuid);
    const [status, setStatus] = useState<SIGNATURE_STATUS>(
        props.uuid ? SIGNATURE_STATUS.COMPLETE : SIGNATURE_STATUS.WAIT,
    );

    const signatureCreate = () => {
        setStatus(SIGNATURE_STATUS.WAIT);
        setCurrentUuid('');

        setLoading((s) => ({ ...s, wait: true }));

        return Request.post('/v1/signature/')
            .then((response) => {
                setCurrentUuid(response.content?.signature?.uuid);
                setStatus(SIGNATURE_STATUS.RECEIVE);

                AppToaster.show({
                    message: response.msg,
                    intent: Intent.PRIMARY,
                    icon: 'error',
                });
            })
            .catch((e) => {
                AppToaster.show({
                    message: e.message,
                    intent: Intent.DANGER,
                });
            })
            .finally(() => {
                setLoading((s) => ({ ...s, wait: false }));
            });
    };

    const signatureReceive = () => {
        setLoading((s) => ({ ...s, receive: true }));

        return Request.put(`/v1/signature/${currentUuid}/receive`)
            .then((response) => {
                setStatus(SIGNATURE_STATUS.COMPLETE);

                AppToaster.show({
                    message: response.msg,
                    intent: Intent.PRIMARY,
                });

                props.onReceived(currentUuid);
            })
            .catch((e) => {
                AppToaster.show({
                    message: e.message,
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            })
            .finally(() => {
                setLoading((s) => ({ ...s, receive: false }));
            });
    };

    return { currentUuid, status, loading, signatureCreate, signatureReceive };
};
