export enum DOCUMENT_TYPE {
    PASSPORT = 'passport',
    DRIVER_LICENSE = 'driver-license',
    ARMY = 'army',
    RESIDENCE = 'residence',
    BORN_DOCUMENT = 'born-document',
    REFUGE = 'refuge',
    OTHER = 'other',
}

export const DOCUMENT_TYPES = [
    DOCUMENT_TYPE.PASSPORT,
    DOCUMENT_TYPE.DRIVER_LICENSE,
    DOCUMENT_TYPE.ARMY,
    DOCUMENT_TYPE.RESIDENCE,
    DOCUMENT_TYPE.BORN_DOCUMENT,
    DOCUMENT_TYPE.REFUGE,
    DOCUMENT_TYPE.OTHER,
];

export const DOCUMENT_TYPE_LABEL = {
    [DOCUMENT_TYPE.PASSPORT]: 'Паспорт',
    [DOCUMENT_TYPE.DRIVER_LICENSE]: 'Водительские права',
    [DOCUMENT_TYPE.ARMY]: 'Военный билет',
    [DOCUMENT_TYPE.RESIDENCE]: 'Вид на жительство',
    [DOCUMENT_TYPE.BORN_DOCUMENT]: 'Свидетельство о рождении',
    [DOCUMENT_TYPE.REFUGE]: 'Справка о временном убежище',
    [DOCUMENT_TYPE.OTHER]: 'Другое',
};
