// Если цифра равна 0 то возвращает пустую строку. Нужен для полей
export function numEmpty(num: number): string {
    return num ? String(num) : '';
}

// Если значение пустое то 0
export function numZero(str: string): number {
    const num = Number(str);
    return num ? num : 0;
}
