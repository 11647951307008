import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    Button,
    FormGroup,
    InputGroup,
    RadioGroup,
    Radio,
    Checkbox,
    Intent,
    MenuItem,
    Position,
    ControlGroup,
} from '@blueprintjs/core';
import { DateInput, TimePicker } from '@blueprintjs/datetime';
import { Flex, Box } from 'reflexbox';
import moment from 'moment-timezone';
import MomentLocaleUtils from 'react-day-picker/moment';

import '@styles/index.scss';

import { Select } from '@components/ui/select';
import { FormLabel } from '@components/ui/form-label';
import {
    CardSuggestCom,
    cardSuggestItemRender,
    fullNameInputStyle,
} from '@components/card-suggest-com';
import { AppToaster } from '@components/ui/toaster';
import { useRandomId } from '@components/ui/use-random-id';
import {
    AddressSuggestCom,
    addressSuggestItemRender,
    mainInputStyle,
} from '@components/address-suggest-com';
import { CardSaved } from '@components/card-saved';
import { useCanNav } from '@components/ui/use-can-nav';
import { useMaskDate } from '@components/ui/use-mask-date';
import {
    IssuedSuggestCom,
    issuedSuggestInputStyle,
    issuedSuggestItemRender,
} from '@components/issued-suggest-com';

import { Layout } from '@containers/layout';

import { GlobalState } from '@resources/reducers';
import { selectPriceById, selectPrices } from '@resources/prices/selectors';
import { initialPrice, Price } from '@resources/prices/_price';
import { CardSuggest } from '@resources/cards/_card-suggest';
import {
    selectAddressSuggests,
    selectCard,
    selectCardLast,
    selectCardSuggests,
    selectIssuedSuggests,
    selectVisitLast,
} from '@resources/cards/selectors';
import { initialVisit, PAY_METHOD, Visit } from '@resources/cards/_visit';
import {
    Card,
    CARD_TYPE,
    CITIZENSHIP_LIST,
    FAMILY_STATUS,
    FAMILY_STATUS_LABEL,
    initialCard,
    STAY_PERIOD_MONTHS,
    STAY_PERIOD_YEARS,
} from '@resources/cards/_card';
import { joinFullName, splitFullName } from '@resources/cards/helpers';
import { User, USER_SEX_VALUE } from '@resources/users/_user';
import { DOCUMENT_TYPES, DOCUMENT_TYPE_LABEL } from '@resources/cards/_doc-type';
import { issetArrayItem, toogleArrayItem } from '@resources/helpers/array';
import {
    addressSuggests,
    cardCreate,
    cardsSuggests,
    cardUpdate,
    card as cardAction,
    issuedSuggests,
} from '@resources/cards/actions';
import { CardsActions } from '@resources/actions/_state';
import { selectCardsActions } from '@resources/actions/selectors';
import { reverseStrDate } from '@resources/helpers/datetime';
import { CAUSE } from '@resources/cards/_cause';
import { Org } from '@resources/orgs/_org';
import { selectOrgs } from '@resources/orgs/selectors';
import { numEmpty, numZero } from '@resources/helpers/string';
import { AddressSuggest, CardUpdatePayload } from '@resources/cards/interfaces';
import { selectDocsTemplatesByNames } from '@resources/config/selectors';
import { selectOffices } from '@resources/offices/selectors';
import { Office } from '@resources/offices/_office';
import { selectProfile } from '@resources/users/selectors';
import { useEffect } from 'react';
import { SignatureModule } from '@containers/signature';

const isBrowser = typeof window !== 'undefined';

type Props = {
    prices: Price[];
    cardSuggests: CardSuggest[];
    addressSuggests: AddressSuggest[];
    cardsActions: CardsActions;
    cardLast: Card;
    visitLast: Visit;
    issuedSuggests: string[];
    orgs: Org[];
    offices: Office[];
    profile: User;
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();

    const [fullNameId] = useRandomId('full_name');
    const [documentNumberId] = useRandomId('document_number');
    const [documentSeriesId] = useRandomId('document_series');
    const [documentReleasedId] = useRandomId('document_released');
    const [phoneId] = useRandomId('phone');
    const [address1Id] = useRandomId('address1');
    const [address2Id] = useRandomId('address2');
    const [polisId] = useRandomId('polis');
    const [snilsId] = useRandomId('snils');
    const [medicalInsuranceOrgId] = useRandomId('medical_insurance_org');

    const maskBornDate = useMaskDate();
    const maskDocumentDate = useMaskDate();
    const maskDocsDate = useMaskDate();

    const [card, setCard] = React.useState<Card>({
        ...initialCard,
        citizenship: isBrowser && localStorage.getItem('field_citizenship'),
        stay_period_years:
            Number(isBrowser && localStorage.getItem('field_stay_period_years')) || 0,
        stay_period_months:
            Number(isBrowser && localStorage.getItem('field_stay_period_months')) || 0,
    });
    const [visit, setVisit] = React.useState<Visit>({
        ...initialVisit,
        docs_datetime: moment().toISOString(),
    });
    const [priceId, setPriceId] = React.useState<number>(0);
    const [priceSum, setPriceSum] = React.useState<number>(0);
    const [payMethod, setPayMethod] = React.useState<PAY_METHOD>(PAY_METHOD.CASH);
    const [causes, setCauses] = React.useState<CAUSE[]>([]);
    const [docs, setDocs] = React.useState<string[]>([]);
    const [citizenshipManual, setCitizenshipManual] = React.useState<boolean>(
        !CITIZENSHIP_LIST.includes(card.citizenship),
    );

    const [inputFullName, setInputFullName] = React.useState<string>('');
    const [copyToActAddress, setCopyToActAddress] = React.useState<boolean>(false); // Совпадает с актуальным адресом
    const [printDocs, setPrintDoc] = React.useState<boolean>(false);

    const price = useSelector((state: GlobalState) => selectPriceById(state, priceId));
    const docsTemplates = useSelector((state: GlobalState) =>
        selectDocsTemplatesByNames(state, price.docs),
    );
    const cardAutoComplete = useSelector((state: GlobalState) => selectCard(state, card.id));

    useCanNav(props.cardsActions.card_create.success, () => {
        resetAll();
        AppToaster.show({ message: 'Карточка добавлена', intent: Intent.SUCCESS, icon: 'tick' });
    });

    useCanNav(props.cardsActions.card_update.success, () => {
        resetAll();
        AppToaster.show({ message: 'Карточка обновлена', intent: Intent.PRIMARY, icon: 'tick' });
    });

    useCanNav(props.cardsActions.visit_sms_sign.success, () => {
        AppToaster.show({
            message: 'Смс с документами отправлен',
            intent: Intent.PRIMARY,
            icon: 'notifications',
        });
    });

    // Сброс выбранных значений по умолчанию
    const resetAll = () => {
        setPrintDoc(true);

        setPriceId(0);
        setCauses([]);
        setInputFullName('');
        setCopyToActAddress(false);
        setPayMethod(PAY_METHOD.CASH);
        setVisit({
            ...initialVisit,
            docs_datetime: moment().toISOString(),
            office_id: props.profile.office_id,
        });
        setCard({
            ...initialCard,
            citizenship: localStorage.getItem('field_citizenship'),
            stay_period_years: Number(localStorage.getItem('field_stay_period_years')) || 0,
            stay_period_months: Number(localStorage.getItem('field_stay_period_months')) || 0,
        });
    };

    const onCause = (cause: CAUSE) => {
        setCauses((prev) => toogleArrayItem(prev, cause));
    };

    const onSubmit = () => {
        if (!visit.office_id) {
            alert('Выберите офис посещения');
            return;
        }

        const payload: CardUpdatePayload = {
            card: card,
            extra: {
                prices: [
                    {
                        ...price,
                        price: priceSum,
                    },
                ],
                visit_pay_method: payMethod,
                visit_docs: docs,
                visit_causes: causes,
                visit_docs_datetime: visit.docs_datetime,
                visit_org_id: visit.org_id,
                visit_office_id: visit.office_id,
            },
        };

        dispatch(card.id ? cardUpdate(payload) : cardCreate(payload));
    };

    const onPasteLastAddress = () => {
        setCard((prev) => ({
            ...prev,
            address1: props.cardLast.address1,
            address2: props.cardLast.address2,
        }));
    };

    // Выбор существующей карточки
    const onCardSelect = (cardSuggest: CardSuggest) => {
        setInputFullName(joinFullName(cardSuggest));

        setCard((prev) => ({
            ...prev,
            ...cardSuggest,
        }));

        dispatch(cardAction({ card_id: cardSuggest.id }));
    };

    // Вставка id офиса
    useEffect(() => {
        setVisit((prev) => ({
            ...prev,
            office_id: props.profile.office_id,
        }));
    }, [props.profile.office_id]);

    // Обновление части имен
    React.useEffect(() => {
        setCard((prev) => ({ ...prev, ...splitFullName(inputFullName) }));

        // Вызываем подсказки
        dispatch(cardsSuggests({ q: inputFullName }));

        if (!inputFullName) {
            setCard((prev) => ({ ...prev, id: 0 }));
        }
    }, [inputFullName]);

    // Копирование данных из актуального адреса в адрес регистрации
    React.useEffect(() => {
        if (copyToActAddress) {
            setCard((prev) => ({
                ...prev,
                address2: card.address1,
                address_data2: card.address_data1,
            }));
        }
    }, [copyToActAddress]);

    React.useEffect(() => {
        dispatch(addressSuggests({ address: card.address1 }));
    }, [card.address1]);

    React.useEffect(() => {
        dispatch(addressSuggests({ address: card.address2 }));
    }, [card.address2]);

    // Вставка данных из автоподсказки
    React.useEffect(() => {
        if (cardAutoComplete.id && card.id) {
            setCard((prev) => ({ ...prev, ...cardAutoComplete }));

            AppToaster.show({
                message: 'Выбрана карточка - ' + joinFullName(cardAutoComplete),
                intent: Intent.PRIMARY,
                icon: 'search',
            });
        }
    }, [cardAutoComplete.id]);

    React.useEffect(() => {
        dispatch(issuedSuggests({ q: card.document_released }));
    }, [card.document_released]);

    React.useEffect(() => {
        setPriceSum(price.price);
        setDocs(price.docs);
    }, [priceId]);

    return (
        <Layout
            isLoading={props.cardsActions.card_create.loading || props.cardsActions.card.loading}
            title="Создание клиента">
            <FormLabel>Клиент:</FormLabel>
            <Flex mb={10}>
                <RadioGroup
                    inline={true}
                    onChange={(e) =>
                        setCard({
                            ...card,
                            type: e.currentTarget.value as CARD_TYPE,
                        })
                    }
                    selectedValue={card.type}>
                    <Radio label="Медобследование" inline={true} value={CARD_TYPE.RESEARCH} />
                    <Radio label="Пациент" inline={true} value={CARD_TYPE.PATIENT} />
                </RadioGroup>
            </Flex>
            <Flex mb={10}>
                <Box width={['30%']}>
                    <FormGroup
                        label="ФИО"
                        labelFor={fullNameId}
                        labelInfo="(обязательно)"
                        helperText={
                            card.id ? `Существующая карточка ${card.unique_id}` : 'Новая карточка'
                        }
                        style={{ marginRight: 10 }}>
                        <CardSuggestCom
                            query={inputFullName}
                            popoverProps={{
                                fill: true,
                                popoverClassName: fullNameInputStyle,
                            }}
                            items={props.cardSuggests}
                            itemRenderer={cardSuggestItemRender}
                            noResults={<MenuItem disabled={true} text="Не найдено" />}
                            resetOnSelect={false}
                            closeOnSelect={true}
                            openOnKeyDown={true}
                            onItemSelect={onCardSelect}
                            onQueryChange={(value) => setInputFullName(value)}
                            inputValueRenderer={() => inputFullName}
                            fill={true}
                            inputProps={{
                                id: fullNameId,
                                fill: true,
                                placeholder: 'Архипов Архип Архипович',
                                leftIcon: 'user',
                                autoComplete: fullNameId,
                                intent: card.id ? Intent.SUCCESS : Intent.NONE,
                            }}
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup
                        label="Дата рождения"
                        labelFor="born-date"
                        style={{ marginRight: 10 }}>
                        <DateInput
                            value={card.born_date ? new Date(card.born_date) : null}
                            fill={true}
                            parseDate={(str) => moment(reverseStrDate(str)).toDate()}
                            formatDate={(date) => date.toLocaleDateString()}
                            placeholder="01.01.2020"
                            minDate={new Date(1, 0, 1940)}
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            onChange={(date) => {
                                if (date) {
                                    setCard((prev) => ({ ...prev, born_date: date.toISOString() }));
                                }
                            }}
                            inputProps={{
                                id: 'born-date',
                                inputRef: maskBornDate,
                            }}
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup
                        label="Контактный телефон"
                        labelFor={phoneId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.phone}
                            fill={true}
                            id={phoneId}
                            type="text"
                            placeholder="+79140000000"
                            autoComplete={phoneId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, phone: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup
                        label="Семейное положение"
                        labelFor="family_status"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.family_status}
                            style={{ width: '100%' }}
                            items={[FAMILY_STATUS.MARRIAGE, FAMILY_STATUS.NOT_MARRIAGE].map(
                                (item) => ({
                                    label: FAMILY_STATUS_LABEL[item],
                                    value: item,
                                }),
                            )}
                            onChange={(value) =>
                                setCard((prev) => ({
                                    ...prev,
                                    family_status: value as FAMILY_STATUS,
                                }))
                            }
                            placeholderLabel="Неизвестно"
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup label="Пол">
                        <RadioGroup
                            onChange={(e) => {
                                const { value } = e.currentTarget;
                                setCard((prev) => ({
                                    ...prev,
                                    sex: value === USER_SEX_VALUE[1] ? true : false,
                                }));
                            }}
                            selectedValue={USER_SEX_VALUE[Number(card.sex)]}
                            inline={true}>
                            <Radio label="Муж." value={USER_SEX_VALUE[1]} />
                            <Radio label="Жен." value={USER_SEX_VALUE[0]} />
                        </RadioGroup>
                    </FormGroup>
                </Box>
            </Flex>

            <FormLabel>Паспортные данные:</FormLabel>
            <Flex mb={10}>
                <Box width={['15%']}>
                    <FormGroup
                        label="Тип документа"
                        labelFor="document_type"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.document_type}
                            style={{ width: '100%' }}
                            items={DOCUMENT_TYPES.map((item) => ({
                                label: DOCUMENT_TYPE_LABEL[item],
                                value: item,
                            }))}
                            onChange={(value) =>
                                setCard((prev) => ({ ...prev, document_type: value }))
                            }
                            placeholderLabel="- Выберите документ -"
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup
                        label="Серия"
                        labelFor={documentSeriesId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.document_series}
                            id={documentSeriesId}
                            placeholder="0000"
                            autoComplete={documentSeriesId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, document_series: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup
                        label="Номер"
                        labelFor={documentNumberId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.document_number}
                            id={documentNumberId}
                            placeholder="001122"
                            autoComplete={documentNumberId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, document_number: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['32%']}>
                    <FormGroup
                        label="Кем выдан"
                        labelFor={documentReleasedId}
                        style={{ marginRight: 10 }}>
                        <IssuedSuggestCom
                            query={card.document_released}
                            popoverProps={{
                                fill: true,
                                popoverClassName: issuedSuggestInputStyle,
                            }}
                            items={props.issuedSuggests}
                            itemRenderer={issuedSuggestItemRender}
                            noResults={<MenuItem disabled={true} text="Не найдено" />}
                            resetOnSelect={false}
                            closeOnSelect={true}
                            openOnKeyDown={true}
                            onItemSelect={(value) =>
                                setCard((prev) => ({ ...prev, document_released: value }))
                            }
                            onQueryChange={(value) =>
                                setCard((prev) => ({ ...prev, document_released: value }))
                            }
                            inputValueRenderer={() => card.document_released}
                            fill={true}
                            inputProps={{
                                id: documentReleasedId,
                                fill: true,
                                placeholder:
                                    'Отделом УФМС России по Республике Саха (Якутия) в г. Якутск',
                                autoComplete: documentReleasedId,
                            }}
                        />
                    </FormGroup>
                </Box>
                <Box width={['15%']}>
                    <FormGroup label="Когда выдан">
                        <DateInput
                            value={card.document_date ? new Date(card.document_date) : null}
                            parseDate={(str) => moment(reverseStrDate(str)).toDate()}
                            formatDate={(date) => date.toLocaleDateString()}
                            placeholder="01.01.2020"
                            minDate={new Date(1, 0, 1940)}
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            onChange={(date) => {
                                if (date) {
                                    setCard((prev) => ({
                                        ...prev,
                                        document_date: date.toISOString(),
                                    }));
                                }
                            }}
                            inputProps={{
                                inputRef: maskDocumentDate,
                            }}
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <Flex mb={10}>
                <Box width={['35%']}>
                    <FormGroup
                        label="Гражданство"
                        labelFor="citizenship"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.citizenship}
                            style={{ width: '100%' }}
                            items={[...CITIZENSHIP_LIST, 'Другое'].map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={(value) => {
                                if (value === 'Другое') {
                                    setCitizenshipManual(true);
                                    setCard((prev) => ({
                                        ...prev,
                                        citizenship: null,
                                    }));
                                } else {
                                    localStorage.setItem('field_citizenship', value);
                                    setCitizenshipManual(false);
                                    setCard((prev) => ({
                                        ...prev,
                                        citizenship: value,
                                    }));
                                }
                            }}
                            placeholderLabel="- Выберите гражданство -"
                        />
                    </FormGroup>

                    {citizenshipManual && (
                        <InputGroup
                            value={card.citizenship}
                            id={polisId}
                            placeholder="Введите гражданство"
                            onChange={(e) => {
                                setCard((prev) => ({ ...prev, citizenship: e.target.value }));
                            }}
                        />
                    )}
                </Box>
                <Box width={['20%']}>
                    <FormGroup
                        label="Срок пребывания"
                        labelFor="stay_period_years"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.stay_period_years}
                            style={{ width: '100%' }}
                            items={[...STAY_PERIOD_YEARS].map((item) => ({
                                label: item + ' г.',
                                value: item,
                            }))}
                            onChange={(value) => {
                                localStorage.setItem('field_stay_period_years', value);
                                setCard((prev) => ({
                                    ...prev,
                                    stay_period_years: value,
                                }));
                            }}
                            placeholderLabel="- Год -"
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup
                        label="&nbsp;"
                        labelFor="stay_period_months"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={card.stay_period_months}
                            style={{ width: '100%' }}
                            items={[...STAY_PERIOD_MONTHS].map((item) => ({
                                label: item + ' мес.',
                                value: item,
                            }))}
                            onChange={(value) => {
                                localStorage.setItem('field_stay_period_months', value);
                                setCard((prev) => ({
                                    ...prev,
                                    stay_period_months: value,
                                }));
                            }}
                            placeholderLabel="- Месяц -"
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <FormLabel>Медицинская страховка:</FormLabel>
            <Flex mb={10}>
                <Box width={['18%']}>
                    <FormGroup label="Полис" labelFor={polisId} style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.polis}
                            id={polisId}
                            placeholder="0000111122223333"
                            autoComplete={polisId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, polis: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['20%']}>
                    <FormGroup label="Снилс" labelFor={snilsId} style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.snils}
                            id={snilsId}
                            placeholder="123-456-789 00"
                            autoComplete={snilsId}
                            onChange={(e) =>
                                setCard((prev) => ({ ...prev, snils: e.target.value }))
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['45%']}>
                    <FormGroup
                        label="Мед. страховая организация"
                        labelFor={medicalInsuranceOrgId}
                        style={{ marginRight: 10 }}>
                        <InputGroup
                            value={card.medical_insurance_org}
                            id={medicalInsuranceOrgId}
                            placeholder="Сахамедстрах"
                            autoComplete={medicalInsuranceOrgId}
                            onChange={(e) =>
                                setCard((prev) => ({
                                    ...prev,
                                    medical_insurance_org: e.target.value,
                                }))
                            }
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <FormLabel>Адреса:</FormLabel>
            <Box mb={15} width={['83%']}>
                <FormGroup
                    label="Фактический адрес"
                    labelFor={address1Id}
                    style={{ marginRight: 10 }}>
                    <AddressSuggestCom
                        items={props.addressSuggests}
                        itemRenderer={addressSuggestItemRender}
                        noResults={<MenuItem disabled={true} text="Не найдено" />}
                        closeOnSelect={true}
                        openOnKeyDown={true}
                        query={card.address1}
                        onItemSelect={(item) =>
                            setCard((prev) => ({
                                ...prev,
                                address1: item.unrestricted_value,
                                address_data1: item.data,
                            }))
                        }
                        onQueryChange={(value) => setCard((prev) => ({ ...prev, address1: value }))}
                        inputValueRenderer={(item) => card.address1}
                        fill={true}
                        popoverProps={{
                            fill: true,
                            position: Position.BOTTOM_LEFT,
                            usePortal: true,
                            popoverClassName: mainInputStyle,
                        }}
                        inputProps={{
                            id: address1Id,
                            autoComplete: address1Id,
                            placeholder:
                                'Россия, Республика Саха (Якутия), г. Якутск, Федора Попова, д. 23, кв. 10',

                            rightElement: (
                                <Button
                                    small={true}
                                    onClick={onPasteLastAddress}
                                    icon="clipboard"
                                    disabled={
                                        !(props.cardLast.address1 && props.cardLast.address2)
                                    }>
                                    Вставить последний адрес
                                </Button>
                            ),
                        }}
                    />
                </FormGroup>

                <FormGroup
                    label="Адрес регистрации"
                    labelFor={address2Id}
                    style={{ marginRight: 10 }}>
                    <AddressSuggestCom
                        items={props.addressSuggests}
                        itemRenderer={addressSuggestItemRender}
                        noResults={<MenuItem disabled={true} text="Не найдено" />}
                        closeOnSelect={true}
                        disabled={copyToActAddress ? true : false}
                        openOnKeyDown={true}
                        query={copyToActAddress ? card.address1 : card.address2}
                        onItemSelect={(item) =>
                            setCard((prev) => ({
                                ...prev,
                                address2: item.unrestricted_value,
                                address_data2: item.data,
                            }))
                        }
                        onQueryChange={(value) => setCard((prev) => ({ ...prev, address2: value }))}
                        inputValueRenderer={(item) => card.address2}
                        fill={true}
                        popoverProps={{
                            fill: true,
                            position: Position.BOTTOM_LEFT,
                            usePortal: true,
                            popoverClassName: mainInputStyle,
                        }}
                        inputProps={{
                            id: address2Id,
                            autoComplete: address2Id,
                            placeholder:
                                'Россия, Республика Саха (Якутия), г. Якутск, Федора Попова, д. 23, кв. 10',
                        }}
                    />
                    <Checkbox
                        checked={copyToActAddress}
                        label="Совпадает с фактическим адресом"
                        onChange={(e) => {
                            setCopyToActAddress((e.target as HTMLInputElement).checked);
                        }}
                    />
                </FormGroup>
            </Box>
            <FormLabel>Документ:</FormLabel>
            <Flex>
                <FormGroup label="Дата документа" labelFor="docs-date" style={{ marginRight: 10 }}>
                    <DateInput
                        value={visit.docs_datetime ? new Date(visit.docs_datetime) : null}
                        parseDate={(str) => new Date(str)}
                        formatDate={(date) => date.toLocaleDateString()}
                        placeholder="01.01.2020"
                        locale="ru"
                        localeUtils={MomentLocaleUtils}
                        minDate={new Date(1, 0, 1940)}
                        onChange={(date) => {
                            if (date) {
                                setVisit((prev) => ({
                                    ...prev,
                                    docs_datetime: date.toISOString(),
                                }));
                            }
                        }}
                        inputProps={{
                            id: 'docs-date',
                            inputRef: maskDocsDate,
                        }}
                    />
                </FormGroup>
                <FormGroup label="Время документа" labelFor="docs-time" style={{ marginRight: 10 }}>
                    <TimePicker
                        value={visit.docs_datetime ? new Date(visit.docs_datetime) : null}
                        selectAllOnFocus={true}
                        onChange={(time) => {
                            if (visit.docs_datetime) {
                                const datetime = new Date(visit.docs_datetime);
                                datetime.setHours(time.getHours());
                                datetime.setMinutes(time.getMinutes());
                                datetime.setSeconds(time.getSeconds());
                                setVisit((prev) => ({
                                    ...prev,
                                    docs_datetime: datetime.toISOString(),
                                }));
                            }
                        }}
                    />
                </FormGroup>
            </Flex>

            <Flex mb={10}>
                <FormGroup
                    label="Услуга"
                    style={{
                        marginRight: 10,
                    }}>
                    <Select
                        value={numEmpty(price.id)}
                        style={{ width: '100%' }}
                        items={props.prices.map((item) => ({
                            label: item.name_short,
                            value: numEmpty(item.id),
                        }))}
                        onChange={(value) => {
                            setPriceId(numZero(value));
                        }}
                        placeholderLabel="- Выберите документ -"
                    />
                </FormGroup>
                <FormGroup
                    label="Стоимость"
                    style={{
                        marginRight: 10,
                    }}>
                    <InputGroup
                        disabled={!priceId}
                        type="number"
                        value={String(priceSum)}
                        onChange={(e) => {
                            setPriceSum(Number(e.currentTarget.value));
                        }}
                        placeholder="1000"
                    />
                </FormGroup>
            </Flex>

            {docsTemplates.length ? (
                <Flex>
                    <FormGroup
                        label="Пакет документов"
                        style={{
                            marginRight: 10,
                        }}>
                        {docsTemplates.map((docTemplate) => (
                            <ControlGroup
                                style={{ alignItems: 'center', marginRight: 10 }}
                                key={docTemplate?.id}>
                                <Checkbox
                                    key={docTemplate?.id}
                                    disabled={!price.id}
                                    checked={issetArrayItem(docs, docTemplate.name)}
                                    inline={true}
                                    style={{ marginRight: 10 }}
                                    label={docTemplate.label}
                                    value={docTemplate.name}
                                    onChange={(e) =>
                                        setDocs(toogleArrayItem(docs, e.currentTarget.value))
                                    }
                                />
                            </ControlGroup>
                        ))}
                    </FormGroup>
                </Flex>
            ) : (
                <React.Fragment />
            )}

            <Flex mb={15}>
                <RadioGroup
                    label="Метод оплаты"
                    inline={true}
                    onChange={(e) => {
                        setPayMethod(e.currentTarget.value as PAY_METHOD);
                    }}
                    selectedValue={payMethod}>
                    <Radio label="Наличные" inline={true} value={PAY_METHOD.CASH} />
                    <Radio label="Карта" inline={true} value={PAY_METHOD.CARD} />
                    <Radio label="По счету" inline={true} value={PAY_METHOD.BILL} />
                </RadioGroup>
            </Flex>

            {payMethod === PAY_METHOD.BILL ? (
                <Flex mb={15}>
                    <FormGroup
                        label="Выберите организацию"
                        labelFor="org_id"
                        style={{ marginRight: 10 }}>
                        <Select
                            value={numEmpty(visit.org_id)}
                            style={{ width: '100%' }}
                            items={props.orgs.map((item) => ({
                                label: item.name,
                                value: numEmpty(item.id),
                            }))}
                            onChange={(value) =>
                                setVisit((prev) => ({ ...prev, org_id: numZero(value) }))
                            }
                            placeholderLabel="- Выберите организацию -"
                        />
                    </FormGroup>
                </Flex>
            ) : (
                <React.Fragment />
            )}

            <Box style={{ width: '30%' }}>
                <FormGroup label="Офис" style={{ marginRight: 10 }}>
                    <Select
                        value={String(visit.office_id)}
                        style={{ width: '100%' }}
                        items={props.offices.map((office) => ({
                            label: office.name,
                            value: String(office.id),
                        }))}
                        onChange={(value) => {
                            setVisit((prev) => ({
                                ...prev,
                                office_id: !!value ? Number(value) : 0,
                            }));
                        }}
                        placeholderLabel="- Выберите офис -"
                    />
                </FormGroup>
            </Box>

            <SignatureModule
                key={card.signature}
                uuid={card.signature}
                onSet={(uuid) => {
                    setCard((s) => ({ ...s, signature: uuid }));
                }}
            />

            <Button
                style={{ marginTop: 30 }}
                intent={card.id ? Intent.PRIMARY : Intent.SUCCESS}
                large={true}
                onClick={onSubmit}>
                {card.id ? 'Сохранить карточку' : 'Создать карточку'}
            </Button>

            <CardSaved isOpen={printDocs} visit={props.visitLast} />
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        prices: selectPrices(state),
        cardSuggests: selectCardSuggests(state),
        addressSuggests: selectAddressSuggests(state),
        cardsActions: selectCardsActions(state),
        cardLast: selectCardLast(state),
        visitLast: selectVisitLast(state),
        issuedSuggests: selectIssuedSuggests(state),
        orgs: selectOrgs(state),
        offices: selectOffices(state),
        profile: selectProfile(state),
    };
}
