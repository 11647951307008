import { Button, ButtonGroup, FormGroup, Intent } from '@blueprintjs/core';
import { FC } from 'react';
import React from 'react';
import { Box } from 'reflexbox';
import { IconNames } from '@blueprintjs/icons';
import { SignatureImage } from '@components/signature/signature-image';
import { useSignature, SIGNATURE_STATUS } from '@components/signature/use-signature';

type Props = {
    uuid: string;
    onSet: (uuid: string) => void;
};

export const SignatureModule: FC<Props> = (props) => {
    const { loading, signatureCreate, signatureReceive, status, currentUuid } = useSignature({
        uuid: props.uuid,
        onReceived: props.onSet,
    });

    return (
        <Box style={{ width: '30%' }}>
            <FormGroup label="Подпись" style={{ marginRight: 10 }}>
                {status === SIGNATURE_STATUS.WAIT && (
                    <Button intent={Intent.NONE} loading={loading.wait} onClick={signatureCreate}>
                        Создать подпись
                    </Button>
                )}

                {status === SIGNATURE_STATUS.RECEIVE && (
                    <ButtonGroup>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={loading.receive}
                            icon={IconNames.TICK}
                            onClick={signatureReceive}>
                            Получить подпись
                        </Button>
                        <Button
                            intent={Intent.NONE}
                            icon={IconNames.REPEAT}
                            loading={loading.wait}
                            onClick={signatureCreate}>
                            Очистить подпись
                        </Button>
                    </ButtonGroup>
                )}

                {status === SIGNATURE_STATUS.COMPLETE && (
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20 }}>
                        <SignatureImage uuid={currentUuid} />
                        <Button
                            intent={Intent.NONE}
                            icon={IconNames.REPEAT}
                            loading={loading.wait}
                            onClick={signatureCreate}>
                            Очистить подпись
                        </Button>
                    </div>
                )}
            </FormGroup>
        </Box>
    );
};
