import React from 'react';
import { IItemRendererProps, Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';

import * as styles from './index.module.scss';
import { AddressSuggest } from '@resources/cards/interfaces';

export const AddressSuggestCom = Suggest.ofType<AddressSuggest>();

export const addressSuggestItemRender = (item: AddressSuggest, p: IItemRendererProps) => {
    return <MenuItem key={p.index} text={item.unrestricted_value} onClick={p.handleClick} />;
};

export const mainInputStyle = styles.main_input;
