import React from 'react';
import { IItemRendererProps, Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import moment from 'moment-timezone';

import * as styles from './index.module.scss';

import { CardSuggest } from '@resources/cards/_card-suggest';
import { FORMAT_DATE_SHORT } from '@resources/helpers/datetime';

export const CardSuggestCom = Suggest.ofType<CardSuggest>();

export const cardSuggestItemRender = (item: CardSuggest, p: IItemRendererProps) => {
    return (
        <MenuItem
            key={item.id}
            text={[
                item.unique_id,
                item.last_name + ' ' + item.first_name + ' ' + item.patronymic,
                moment.utc(item.born_date).local().format(FORMAT_DATE_SHORT),
            ].join(' / ')}
            onClick={p.handleClick}
        />
    );
};

export const inputValueRenderer = (item: CardSuggest) => {
    return [item.last_name, item.first_name, item.patronymic].join(' ');
};

export const fullNameInputStyle = styles.full_name_input;
