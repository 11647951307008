import React from 'react';
import { Box } from 'reflexbox';
import { ControlGroup, Collapse, Button, Intent } from '@blueprintjs/core';

import * as styles from './index.module.scss';

import { Visit } from '@resources/cards/_visit';
import { useDocumentDownload } from '@resources/cards/use-document-download';

type Props = {
    isOpen: boolean;
    visit: Visit;
};

export const CardSaved = (props: Props) => {
    const { downloadDocumentByLastVisit, downloadDocumentByDocs, loadingDocument } =
        useDocumentDownload();

    return (
        <Collapse isOpen={props.isOpen}>
            <div className={styles.container}>
                <div className={styles.title}>Для распечатки:</div>
                <div className={styles.buttons}>
                    <Box mb={1}>
                        <ControlGroup>
                            <Button
                                alignText="left"
                                small={true}
                                disabled={!props.visit.docs.length}
                                intent={Intent.NONE}
                                icon="print"
                                loading={loadingDocument}
                                onClick={() => {
                                    downloadDocumentByLastVisit(props.visit.id);
                                }}>
                                Пакет документов
                            </Button>
                        </ControlGroup>
                    </Box>
                    <Box mb={1}>
                        <ControlGroup>
                            <Button
                                alignText="left"
                                small={true}
                                disabled={!props.visit.docs.length}
                                intent={Intent.NONE}
                                icon="print"
                                loading={loadingDocument}
                                onClick={() => {
                                    downloadDocumentByDocs(props.visit.id, ['end_med_green_paper']);
                                }}>
                                Медзаключение (green paper)
                            </Button>
                        </ControlGroup>
                    </Box>
                    {!props.visit.docs.length && '-'}
                </div>
            </div>
        </Collapse>
    );
};
