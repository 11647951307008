import React from 'react';

import { IS_BROWSER } from '@resources/helpers/ssr';

const useMaskInput = IS_BROWSER ? require('use-mask-input').default : () => {};

export const useMaskDate = () => {
    if (IS_BROWSER) {
        return useMaskInput({
            mask: ['99.99.9999'],
            options: {
                placeholder: '__.__._____',
            },
        });
    } else {
        return undefined;
    }
};
